import React from 'react'
import Upload from '../components/Upload'
import "../components/Publics/css/index.css"

const UploadImages = () => {
  return (
    <div>
      <Upload/>
    </div>
  )
}

export default UploadImages